import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Components/HomePage";
import SubscriptionForm from "./Components/Subscriptions/SubscriptionForm";
import { Footer } from "./Components/Header/Footer";
import { News } from "./Components/news/news";
import { NewsDetails } from "./Components/news/NewsDetails";
import EventList from "./Components/Events/EventList";
import EventDetails from "./Components/Events/EventDetails";

const App = () => {
  return (
    <BrowserRouter>
      <div className="full-height bg-dark">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/events/*" element={<EventList />} />
          <Route path="/events/:id" element={<EventDetails />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<NewsDetails />} />
          <Route path="/membership" element={<SubscriptionForm />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
