import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Mission() {
  return (
    <Container fluid className="pt-5 pb-5 bg-dark text-white">
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <h1 className="display-4">Our Mission</h1>
          <p className="mt-4" style={{ fontSize: "1.1rem", lineHeight: "1.7" }}>
            The mission of the World Hazara Council is to promote and defend the
            cultural, social, and civil rights of the Hazara people worldwide,
            as well as other at-risk marginalized populations. Our mission is to
            empower those at risk by amplifying their voices and mobilizing
            against attacks on marginalized populations.
          </p>
          <p style={{ fontSize: "1.1rem", lineHeight: "1.7" }}>
            We provide humanitarian assistance to those in need and develop
            programs that improve the quality of life for marginalized
            populations. The WHC serves as an umbrella organization,
            representing diverse Hazara communities and organizations worldwide,
            facilitating greater cooperation between them.
          </p>
          <p style={{ fontSize: "1.1rem", lineHeight: "1.7" }}>
            We aim to be a resource to defend human rights and every person's
            freedom to live. We are a non-political and non-religious
            organization that advocates for marginalized communities in media,
            governments, and civil society.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Mission;
