import React from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight } from "@fortawesome/free-regular-svg-icons";

function About() {
  return (
    <Container fluid className="py-5 bg-light text-dark">
      <h1 className="text-center mb-5">About Us</h1>
      <Row className="align-items-center">
        <Col md={12}>
          <p
            className="text-justify"
            style={{
              fontSize: "1.1rem",
              lineHeight: "1.6",
              textAlign: "justify",
            }}
          >
            The World Hazara Council (WHC) is a civil, social, and cultural
            non-profit organization dedicated to promoting the rights and
            development of Hazara communities worldwide. The WHC is apolitical,
            non-religious, and strictly non-discriminatory regarding gender,
            sexual orientation, religious or political beliefs. Formally
            inaugurated in early 2013, the WHC serves as an umbrella
            organization uniting Hazara communities globally. The council
            operates on democratic principles, holding general assemblies every
            two years, where leadership is elected. The president may serve no
            more than two consecutive terms, ensuring transparency and
            accountability in governance. The WHC has established branches in
            Austria, Germany, Sweden, USA, and France, with the main branch
            registered in Brussels, Belgium. Each branch functions as an
            independent human rights and cultural organization, following the
            laws and regulations of its respective country. Despite their
            autonomy, all branches are bound by the WHC’s core values and
            principles, such as gender equality, democratic elections, and
            non-discrimination. The WHC does not support or have ties with any
            political or non-political organizations whose roles in civil
            conflict are questionable or do not align with WHC's core values.
            Since its founding, the WHC has held six general assemblies,
            steadily growing into a democratic institution that advocates for
            the cultural, social, and civil rights of the Hazara people. All
            members, regardless of age, education, beliefs, or gender, have
            equal rights and are actively involved in shaping the organization’s
            future. By promoting Hazara identity and global solidarity, the WHC
            continues to make a significant historical impact, building
            connections and fostering cooperation among Hazara communities
            around the world.
          </p>
        </Col>
      </Row>

      <h2 className="section-title text-center mt-5 mb-4">Our Priorities</h2>
      <ListGroup className="px-3">
        {[
          "Establish a worldwide platform for Hazara solidarity and a point of contact for consultation and assistance.",
          "Work on human rights issues concerning Hazaras across Afghanistan.",
          "Advocate and lobby for better protection for all refugees and people seeking asylum from Afghanistan.",
          "Establish a think tank to engage in research and promote social mobility for Hazara communities worldwide.",
          "Promote integration of Hazara communities into host countries while preserving their cultural identity.",
          "Advocate for democratization and equal representation for Hazaras in political and social spheres.",
          "Develop initiatives to safeguard communities from extremism, fundamentalism, and the spread of intolerance,while promoting peaceful coexistence and mutual respect.",
          "Promote access to quality education and vocational training for Hazara youth and marginalized communities.",
          "Foster diversity, inclusion, and equity within Hazara and broader communities.",
          "Advocate for global human rights, combating discrimination and marginalization in all forms.",
          "Encourage civic engagement and participation in local and global governance.",
          "Engage in peacebuilding efforts to resolve conflicts and promote stability in Afghanistan and across the globe.",
          "Promote harmony and cultural understanding through the arts, music, and cultural exchange, fostering creativity and celebrating diversity within communities.",
        ].map((item, index) => (
          <ListGroup.Item className="text-gray fs-5 border-0 mb-3" key={index}>
            <FontAwesomeIcon
              icon={faHandPointRight}
              className="me-2"
              color="#007bff"
            />
            {item}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );
}

export default About;
