import React, { useState } from "react";
import "./SubscriptionForm.css";
import { Form, Button, Container, Card } from "react-bootstrap";
import PageHeader from "../Header/PageHeader";

const SubscriptionForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [address, setAddress] = useState({
    country: "",
    street: "",
    postcode: "",
    houseNumber: "",
  });
  const [showFarsiTranslation, setShowFarsiTranslation] = useState(false);

  const [agreed, setAgreed] = useState(false);
  const countryCodes = [
    { name: "Argentina", code: "+54" },
    { name: "Australia", code: "+61" },
    { name: "Austria", code: "+43" },
    { name: "Belgium", code: "+32" },
    { name: "Brazil", code: "+55" },
    { name: "Bulgaria", code: "+359" },
    { name: "Canada", code: "+1" },
    { name: "Croatia", code: "+385" },
    { name: "Cyprus", code: "+357" },
    { name: "Czech Republic", code: "+420" },
    { name: "Denmark", code: "+45" },
    { name: "Estonia", code: "+372" },
    { name: "Finland", code: "+358" },
    { name: "France", code: "+33" },
    { name: "Germany", code: "+49" },
    { name: "Greece", code: "+30" },
    { name: "Hungary", code: "+36" },
    { name: "Ireland", code: "+353" },
    { name: "Italy", code: "+39" },
    { name: "Latvia", code: "+371" },
    { name: "Lithuania", code: "+370" },
    { name: "Luxembourg", code: "+352" },
    { name: "Malta", code: "+356" },
    { name: "Mexico", code: "+52" },
    { name: "Netherlands", code: "+31" },
    { name: "Norway", code: "+47" },
    { name: "Poland", code: "+48" },
    { name: "Portugal", code: "+351" },
    { name: "Romania", code: "+40" },
    { name: "Slovakia", code: "+421" },
    { name: "Slovenia", code: "+386" },
    { name: "Spain", code: "+34" },
    { name: "Sweden", code: "+46" },
    { name: "Switzerland", code: "+41" },
    { name: "Turkey", code: "+90" },
    { name: "United Kingdom", code: "+44" },
    { name: "United States", code: "+1" },
    // Add more country codes as needed
  ];

  const handleToggleTranslation = () => {
    setShowFarsiTranslation(!showFarsiTranslation);
  };
  const handlePhoneChange = (e: any) => {
    const inputPhone = e.target.value;
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;

    if (phoneRegex.test(inputPhone)) {
      setPhone(inputPhone);
    }
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const fullAddress = `${address.houseNumber}, ${address.street}, ${address.postcode}, ${address.country}`;
    if (!countryCode || !phone || !phone.match(/^\d+$/)) {
      alert("Please enter a valid country code and phone number.");
      return;
    }
    // const formData = {
    //   firstName,
    //   lastName,
    //   phone: countryCode + phone,
    //   address: fullAddress,
    //   email,
    //   message: "",
    // };
    if (agreed) {
      const mailtoLink = `mailto:info@worldhazaracouncil.info?subject=Subscription%20Request&body=First%20Name:%20${encodeURIComponent(
        firstName
      )}%0D%0ALast%20Name:%20${encodeURIComponent(
        lastName
      )}%0D%0AEmail:%20${encodeURIComponent(
        email
      )}%0D%0APhone:%20${encodeURIComponent(countryCode)}${encodeURIComponent(
        phone
      )}%0D%0AAddress:%20${encodeURIComponent(fullAddress)}`;
      window.location.href = mailtoLink;

      // const response = await fetch(
      //   `https://sheets.googleapis.com/v4/spreadsheets/${process.env.REACT_APP_SPREADSHEET_ID}`,
      //   {
      //     method: "POST",
      //     mode: "no-cors", // Required to bypass CORS restrictions
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //     },
      //     body: new URLSearchParams(formData),
      //   }
      // );
      // console.log(response);
      // if (response) {
      //   alert("Form submitted successfully");
      // }

      setLastName("");
      setEmail("");
      setPhone("");
      setAddress({
        country: "",
        street: "",
        postcode: "",
        houseNumber: "",
      });
      setAgreed(false);
    } else {
      alert(
        "Please agree to share your information before submitting the form."
      );
    }
  };

  return (
    <div
      className="body"
      style={{ minHeight: "100vh", justifyContent: "center" }}
    >
      <PageHeader onBack={() => window.history.back()} />
      <div
        className="subscription-form-container "
        style={{ width: "100%", flexDirection: "column" }}
      >
        <Container className="subscription-form">
          <Card className="subscription-card">
            <Card.Body>
              <h2 className="text-center bg-dark text-white">
                Send a Membership Request
              </h2>
              <p
                className="mb-0"
                style={{ fontSize: "1rem", fontFamily: "Arial, sans-serif" }}
              >
                <span className="text-danger">* </span>
                This is not a membership form!
              </p>
              <p
                className="mb-0"
                style={{ fontSize: "1rem", fontFamily: "Arial, sans-serif" }}
              >
                <span className="text-danger">* </span>
                This form will help us to decide whether to send you a
                membership requirement from or not.
              </p>
              <p
                className="mb-0"
                style={{ fontSize: "1rem", fontFamily: "Arial, sans-serif" }}
              >
                This form is open to all individuals who are interested in
                becoming WHC member. If you would like to join as a new member,
                please be informed that WHC membership requires meeting certain
                criteria and entails a yearly membership fee. The membership fee
                is typically collected at the beginning of your membership. We
                encourage you to familiarize yourself with the specific criteria
                and the membership fee structure. You can do so by reaching out
                to current members for guidance or by contacting WHC directly
                via email.
              </p>
              <button onClick={handleToggleTranslation}>
                {showFarsiTranslation ? "Hide Farsi Translation" : "فارسی"}
              </button>
              {showFarsiTranslation && (
                <div
                  className="mb-0"
                  style={{ fontSize: "1rem", fontFamily: "Arial, sans-serif" }}
                >
                  {/* Farsi translation */}
                  <p
                    className="mb-0"
                    style={{
                      fontSize: "1rem",
                      fontFamily: "Arial, sans-serif",
                    }}
                  >
                    این فرم برای افرادی که علاقه مند به عضو شدن در این شورا
                    هستند باز است. اگر می‌خواهید به عنوان یک عضو جدید به شورا
                    جهانی هزاره ها بپیوندید لطفاً مطلع باشید که عضویت شورای
                    جهانی هزاره مستلزم رعایت معیارهای خاصی است و مستلزم پرداخت
                    هزینه عضویت سالانه است. هزینه عضویت معمولاً در ابتدای عضویت
                    شما جمع آوری می شود. ما شما را تشویق می کنیم که با معیارهای
                    خاص و ساختار حق عضویت آشنا شوید. می توانید این کار را با
                    تماس با اعضای فعلی برای راهنمایی یا تماس مستقیم با شورای
                    جهانی هزاره از طریق ایمیل انجام دهید.
                  </p>
                </div>
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="firstName">
                  <Form.Label>First Name:</Form.Label>
                  <Form.Control
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="lastName">
                  <Form.Label>Last Name:</Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Email:</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="phone">
                  <Form.Label>Phone:</Form.Label>
                  <div className="phone-input-container d-flex">
                    <Form.Control
                      as="select"
                      className="country-code"
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                      required
                    >
                      <option value="">Select Country Code</option>
                      {countryCodes.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name} ({country.code})
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control
                      type="tel"
                      className="phone-number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </div>
                </Form.Group>

                <Form.Group controlId="country">
                  <Form.Label>Country:</Form.Label>
                  <Form.Control
                    type="text"
                    value={address.country}
                    onChange={(e) =>
                      setAddress({ ...address, country: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <Form.Group controlId="street">
                  <Form.Label>Street:</Form.Label>
                  <Form.Control
                    type="text"
                    value={address.street}
                    onChange={(e) =>
                      setAddress({ ...address, street: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <Form.Group controlId="postcode">
                  <Form.Label>Postcode:</Form.Label>
                  <Form.Control
                    type="text"
                    value={address.postcode}
                    onChange={(e) =>
                      setAddress({ ...address, postcode: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <Form.Group controlId="houseNumber">
                  <Form.Label>House Number:</Form.Label>
                  <Form.Control
                    type="text"
                    value={address.houseNumber}
                    onChange={(e) =>
                      setAddress({ ...address, houseNumber: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <Form.Group controlId="agreed">
                  <Form.Check
                    type="checkbox"
                    label="I agree to share my information for this event and understand that it will not be shared with third parties."
                    checked={agreed}
                    onChange={(e) => setAgreed(e.target.checked)}
                    required
                  />
                </Form.Group>
                <Button type="submit" className="submit-button">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
};

export default SubscriptionForm;
