import React from "react";

export const Footer = () => {
  return (
    <footer className="bg-primary text-white py-5 flex-shrink-0">
      <div className="container d-flex flex-column justify-content-between align-items-center">
        {/* Social Media Icons */}
        <div className="d-flex justify-content-center mb-4">
          <a
            href="https://twitter.com/whcorg?lang=en"
            target="_blank"
            rel="noreferrer noopener"
            className="mx-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="white"
              className="bi bi-twitter"
              viewBox="0 0 16 16"
            >
              <path d="M15.938 3.421c-0.578 0.258-1.2 0.434-1.875 0.512 0.675-0.406 1.194-1.050 1.438-1.817-0.633 0.374-1.336 0.646-2.088 0.793-0.599-0.641-1.454-1.040-2.396-1.040-1.812 0-3.284 1.472-3.284 3.285 0 0.258 0.03 0.512 0.09 0.758-2.731-0.135-5.146-1.439-6.76-3.419-0.283 0.485-0.446 1.05-0.446 1.654 0 1.14 0.579 2.144 1.457 2.732-0.535-0.018-1.04-0.164-1.48-0.408v0.04c0 1.588 1.13 2.912 2.63 3.215-0.276 0.075-0.568 0.116-0.868 0.116-0.212 0-0.418-0.02-0.618-0.058 0.418 1.303 1.628 2.252 3.063 2.275-1.124 0.881-2.536 1.405-4.078 1.405-0.264 0-0.52-0.015-0.774-0.045 1.458 0.933 3.188 1.476 5.043 1.476 6.051 0 9.355-5.02 9.355-9.355l-0.011-0.427c0.641-0.469 1.199-1.055 1.644-1.723z" />
            </svg>
          </a>
          <a
            href="https://www.facebook.com/YourWorldHazaraCouncil/"
            target="_blank"
            rel="noreferrer noopener"
            className="mx-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="white"
              viewBox="0 0 24 24"
            >
              <path d="M12 1.5C6.21 1.5 1.5 6.21 1.5 12C1.5 17.79 6.21 22.5 12 22.5C17.79 22.5 22.5 17.79 22.5 12C22.5 6.21 17.79 1.5 12 1.5ZM12 3C16.97 3 21 7.03 21 12C21 15.26 19.26 18 16 18H14.53V14.81H16.53L17 12H14.53V10.06C14.53 8.47 15.62 7.38 17.12 7.38H17.88V4.19C17.53 4.17 16.42 4.13 15.33 4.13C12.08 4.13 9.71 6.29 9.71 9.51V12H7.41V14.81H9.71V18H7.47C4.21 18 2 15.77 2 12.5C2 7.03 6.03 3 11.5 3H12Z" />
            </svg>
          </a>
          <a
            href="https://www.whc.international/"
            target="_blank"
            rel="noreferrer noopener"
            className="mx-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="white"
              className="bi bi-globe"
              viewBox="0 0 16 16"
            >
              <path d="M8 14.5c-2.482 0-4.676-1.345-5.852-3.344C2.448 9.71 2 8.672 2 7.5S2.448 5.29 2.148 4.844c1.176-1.999 3.37-3.344 5.852-3.344s4.676 1.345 5.852 3.344c0.3 0.456 0.748 1.494 0.748 2.656s-0.448 1.21-0.748 1.656c-1.176 1.999-3.37 3.344-5.852 3.344zM8 3c-1.81 0-3.498 0.669-4.764 1.876-0.081 0.073-0.166 0.151-0.253 0.236A7.456 7.456 0 0 0 1 7.5c0 1.13 0.259 2.216 0.763 3.261 0.087 0.085 0.172 0.163 0.253 0.236C4.502 12.331 6.19 13 8 13s3.498-0.669 4.764-1.876c0.081-0.073 0.166-0.151 0.253-0.236C14.741 9.716 15 8.63 15 7.5s-0.259-2.216-0.763-3.261c-0.087-0.085-0.172-0.163-0.253-0.236C11.498 3.669 9.81 3 8 3zm0 11c-1.55 0-3.084-0.585-4.268-1.646-0.028-0.023-0.057-0.046-0.085-0.07C2.767 11.337 2.5 10.468 2.5 9.5s0.267-1.837 1.147-2.784c0.028-0.023 0.057-0.046 0.085-0.07C4.916 6.585 6.45 6 8 6c1.55 0 3.084 0.585 4.268 1.646 0.028 0.023 0.057 0.046 0.085 0.07C13.233 7.663 13.5 8.532 13.5 9.5s-0.267 1.837-1.147 2.784c-0.028 0.023-0.057 0.046-0.085 0.07C11.084 13.415 9.55 14 8 14z" />
            </svg>
          </a>
        </div>

        {/* Footer Text */}
        <div className="text-center mt-3">
          <small>
            &copy; {new Date().getFullYear()} World Hazara Council. All rights
            reserved.
          </small>
        </div>
      </div>
    </footer>
  );
};
