import { useState } from "react";
import { Form, Button, Container, Alert } from "react-bootstrap";
const SLACK_WEBHOOK_URL = process.env.NEXT_PUBLIC_APP_SLACK_WEBHOOK_URL || "";

const QuestionBox = () => {
  const [question, setQuestion] = useState("");
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState("");

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    try {
      // Send request to the Next.js API route with question and email
      const res = await fetch(SLACK_WEBHOOK_URL, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ question, email }),
      });

      const data = await res.json();

      if (res.ok) {
        setResponse(
          data.message || "Your question has been sent successfully!"
        );
        setQuestion("");
        setEmail(""); // Clear the email field after submission
      } else {
        const errorMessage =
          data.message || data.error || "Unknown error occurred.";
        setResponse(`Error sending question: ${errorMessage}`);
        console.error("Server error:", data);
      }
    } catch (error) {
      setResponse("Error connecting to the server. Please try again.");
      console.error("Network error:", error);
    }
  };

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <Container className="my-4">
      <h2>Idea, Suggestions, or Question?</h2>
      <Form onSubmit={handleSubmit}>
        {/* Email input field */}
        <Form.Group controlId="formEmail">
          <Form.Label>Your email:</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
            placeholder="Enter your email"
          />
        </Form.Group>

        {/* Question input field */}
        <Form.Group controlId="formQuestion" className="mt-3">
          <Form.Label>Type your question here:</Form.Label>
          <Form.Control
            type="text"
            value={question}
            onChange={handleQuestionChange}
            required
            placeholder="Enter your question"
          />
        </Form.Group>

        {/* Submit button */}
        <Button className="mt-3" variant="primary" type="submit">
          Submit
        </Button>
      </Form>

      {/* Response message */}
      {response && (
        <Alert
          variant={response.includes("Error") ? "danger" : "success"}
          className="mt-3"
        >
          {response}
        </Alert>
      )}
    </Container>
  );
};

export default QuestionBox;
