import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Spinner, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { organizationId, token } from "../../config";
import PageHeader from "../Header/PageHeader";

const EventList: React.FC = () => {
  const [events, setEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        let url = `https://www.eventbriteapi.com/v3/organizations/${organizationId}/events/`;
        let allEvents: any[] = [];

        while (url) {
          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          allEvents = allEvents.concat(data.events); // Append the events from this page

          // Check for pagination
          url = data.pagination.next_page
            ? data.pagination.next_page.url
            : null;
        }

        setEvents(allEvents);
      } catch (error: any) {
        setError(`Error fetching events: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchEvents();
  }, []);

  if (loading)
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  if (error) return <p>{error}</p>;
  const sortedEvents = events.sort(
    (a, b) => new Date(b.start.utc).getTime() - new Date(a.start.utc).getTime()
  );
  return (
    <div
      style={{
        textAlign: "center",
        width: "100%",
        backgroundColor: "#121212",
        minHeight: "100vh",
      }}
    >
      <PageHeader onBack={() => window.history.back()} />
      <Container className="mt-4 event-list-container ">
        <Row className="event-list">
          {events.length > 0 ? (
            sortedEvents.map((event: any) => (
              <Col
                key={event.id}
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                className="mb-4 align-items-center align-self-center align-content-center"
              >
                <Card className="event-card">
                  {event.logo && (
                    <Card.Img
                      variant="top"
                      src={event.logo.url}
                      alt={event.name.text}
                      style={{
                        height: "160px",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  )}
                  <Card.Body>
                    <Card.Title>{event.name.text}</Card.Title>
                    <Card.Text>
                      <strong>Start:</strong>{" "}
                      {new Date(event.start.local).toLocaleString()}
                      <br />
                      <strong>End:</strong>{" "}
                      {new Date(event.end.local).toLocaleString()}
                    </Card.Text>
                    <Link
                      to={`/events/${event.id}`}
                      className="btn btn-primary w-100"
                    >
                      View Details
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col>
              <Alert variant="info">No events found.</Alert>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default EventList;
