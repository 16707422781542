import React, { useState } from "react";
import { Carousel, Image } from "react-bootstrap";

interface SlideshowProps {
  imagePaths: string[];
}

const Slideshow: React.FC<SlideshowProps> = ({ imagePaths }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} interval={3000}>
      {imagePaths.map((path, idx) => (
        <Carousel.Item key={idx}>
          <Image
            className="d-block w-100"
            src={process.env.PUBLIC_URL + path}
            alt={`Slide ${idx + 1}`}
            fluid
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Slideshow;
