import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const Counter: React.FC = () => {
  const [officialBranchesCount, setOfficialBranchesCount] = useState(0);
  const [membersCount, setMembersCount] = useState(0);
  const [yearlyEventsCount, setYearlyEventsCount] = useState(0);

  const officialBranchesTarget = 6;
  const membersTarget = 419;
  const yearlyEventsTarget = 30;

  const officialBranchesInterval = useRef<NodeJS.Timeout | null>(null);
  const membersInterval = useRef<NodeJS.Timeout | null>(null);
  const yearlyEventsInterval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const intervalTime = 10; // Interval time in milliseconds

    const updateCount = (
      setter: React.Dispatch<React.SetStateAction<number>>,
      target: number,
      intervalRef: React.MutableRefObject<NodeJS.Timeout | null>
    ) => {
      intervalRef.current = setInterval(() => {
        setter((prevCount) => {
          if (prevCount >= target) {
            clearInterval(intervalRef.current!);
            return target;
          }
          return prevCount + 1;
        });
      }, intervalTime);
    };

    updateCount(
      setOfficialBranchesCount,
      officialBranchesTarget,
      officialBranchesInterval
    );
    updateCount(setMembersCount, membersTarget, membersInterval);
    updateCount(setYearlyEventsCount, yearlyEventsTarget, yearlyEventsInterval);

    return () => {
      if (officialBranchesInterval.current)
        clearInterval(officialBranchesInterval?.current);
      if (membersInterval.current) clearInterval(membersInterval.current);
      if (yearlyEventsInterval.current)
        clearInterval(yearlyEventsInterval.current);
    };
  }, []);

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col xs={12} md={4} lg={3} className="mb-5 mb-lg-4">
          <Card
            className="counter-box bg-light text-center border-0 shadow-lg p-4"
            style={{ height: "250px" }}
          >
            <Card.Body className="d-flex flex-column align-items-center justify-content-center h-100">
              <Card.Title
                className="counter-title fs-4 mb-3"
                style={{ fontSize: "1.5rem", fontWeight: "bold" }}
              >
                Branch Locations
              </Card.Title>
              <Card.Text
                className="counter-text fs-1"
                style={{ fontSize: "2.5rem", fontWeight: "bold" }}
              >
                {officialBranchesCount}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4} lg={3} className="mb-5 mb-lg-4">
          <Card
            className="counter-box bg-light text-center border-0 shadow-lg p-4"
            style={{ height: "250px" }}
          >
            <Card.Body className="d-flex flex-column align-items-center justify-content-center h-100">
              <Card.Title
                className="counter-title fs-4 mb-3"
                style={{ fontSize: "1.5rem", fontWeight: "bold" }}
              >
                Active Members
              </Card.Title>
              <Card.Text
                className="counter-text fs-1"
                style={{ fontSize: "2.5rem", fontWeight: "bold" }}
              >
                {membersCount}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4} lg={3} className="mb-5 mb-lg-4">
          <Card
            className="counter-box bg-light text-center border-0 shadow-lg p-4"
            style={{ height: "250px" }}
          >
            <Card.Body className="d-flex flex-column align-items-center justify-content-center h-100">
              <Card.Title
                className="counter-title fs-4 mb-3"
                style={{ fontSize: "1.5rem", fontWeight: "bold" }}
              >
                Annual Events
              </Card.Title>
              <Card.Text
                className="counter-text fs-1"
                style={{ fontSize: "2.5rem", fontWeight: "bold" }}
              >
                {yearlyEventsCount}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Counter;
