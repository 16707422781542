import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

export function Info() {
  return (
    <Container fluid className="pt-5 pb-5 ">
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <h1 className="display-4 mb-4">Important Notice!</h1>
          <p className="lead">
            We are in the process of migrating our legacy websites. Until then,
            please visit our current website for more information:
          </p>
          <a
            href="https://www.whc.international"
            target="_blank"
            rel="noreferrer noopener"
            className="d-inline-block mb-4"
          >
            <Button variant="primary" size="lg">
              Visit World Hazara Council
            </Button>
          </a>
          <p className="text-muted">
            Thank you for your patience and continued support during this
            transition.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
