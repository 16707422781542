import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Card, Spinner, Alert, Image } from "react-bootstrap";
import PageHeader from "../Header/PageHeader";
import logo from "../../Images/logo.png";

// interface NewsItem {
//   id: number;
//   title: string;
//   content: string;
// }

export const NewsDetails: React.FC = () => {
  const location = useLocation();
  const { id, title, content, date, issuedBY, faHashtag } =
    location.state || {};

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  if (loading)
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </Container>
    );

  if (error)
    return (
      <Container className="mt-4">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );

  return (
    <div
      style={{
        textAlign: "center",
        height: "100vh",
        width: "100%",
        backgroundColor: "#121212",
        color: "white", // Ensure the text stands out on dark background
      }}
    >
      <PageHeader onBack={() => window.history.back()} />

      {/* Organization logo section */}
      <Container className="mt-4">
        <Image
          src={logo}
          alt="Organization Logo"
          fluid
          style={{ maxWidth: "200px", marginBottom: "20px" }} // Adjust size and margin
        />
        <h4>World Hazara Council</h4>
        <p>Working for peace and human rights worldwide</p>
      </Container>

      {/* News content section */}
      <Container className="mt-4">
        <Card>
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>
              {content}
              {id === 3 && (
                <>
                  For more information, read the{" "}
                  <a
                    href="https://www.hrw.org/news/2024/09/13/afghanistans-hazara-community-needs-protection"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Human Rights Watch report on the need for protection of
                    Afghanistan’s Hazara community
                  </a>
                  .
                </>
              )}
            </Card.Text>
            <Card.Text>{date}</Card.Text>
            <Card.Text>{issuedBY}</Card.Text>
            <Card.Text>{faHashtag}</Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};
