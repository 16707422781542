import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Slideshow from "./ImageSlider/Slideshow";
import congress1 from "../Images/firsthCongress.jpeg";
import congress3 from "../Images/3thCongress.jpeg";
import congress4 from "../Images/4thCongress.jpeg";
import congress5 from "../Images/5thCongress.jpeg";
import congress6 from "../Images/6thCongress.jpeg";
import "../App.css";

function Home() {
  const [dynamicText, setDynamicText] = useState("Loading ...");

  useEffect(() => {
    const timer = setTimeout(() => {
      setDynamicText(
        "To work on human rights issues concerning Hazaras across Afghanistan, Pakistan, and Iran."
      );
    }, 5000);

    return () => clearTimeout(timer); // Clean up the timer when the component unmounts
  }, []);
  const imagePaths = [congress6, congress5, congress4, congress3, congress1];

  return (
    <div className="full-height width-full">
      <div className="main-image">
        <div className="main-image-text-container">
          <div className="main-image-text">
            <Slideshow imagePaths={imagePaths} />
          </div>
        </div>
      </div>
      <div className="p-4">
        <Card
          body
          className="bg-dark p-4 shadow-lg h-100 d-flex flex-column justify-content-center align-items-center"
        >
          <h3
            className=" text-white"
            style={{
              fontSize: "1.3rem",
              lineHeight: "1.6",
              textAlign: "justify",
            }}
          >
            The World Hazara Council (WHC) is an international organization that
            serves as the global representative of Hazara communities across the
            world. As a non-partisan, non-political entity, the WHC is dedicated
            to preserving and promoting the cultural heritage, social rights,
            and civil liberties of the Hazara people. The WHC actively advocates
            for the Hazara community, working to amplify their voices in
            international media, engage with governments, and collaborate with
            civil society organizations. The council plays a vital role in
            raising awareness about the challenges faced by Hazaras, including
            systemic discrimination and ongoing persecution. In addition to
            cultural and social advocacy, the WHC is committed to human rights
            and justice for the Hazara people, striving to secure a future where
            they can live in safety, dignity, and equality.
          </h3>
        </Card>
      </div>
      <div className="text-center mt-4">
        <h1 className="text-primary font-weight-bold">{dynamicText}</h1>
      </div>
    </div>
  );
}

export default Home;
