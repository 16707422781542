import React, { useEffect, useState } from "react";
import Header from "./Header/Header";
import { Info } from "./Info";
import Mission from "./Mission";
import { Vision } from "./Vision";
import About from "./About";
import Home from "./Home";
import Counter from "./Counter/Counter";
import { Navbar, Nav, Button, Container, Row, Col } from "react-bootstrap";
import AdministrationTeam from "./teamMembers";
import StopHazaraGenocide from "./StopHazaraGenocide";
import CookieConsent from "../utility/CookieConsent";
import QuestionBox from "./QuestionBox";

function App() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScroll = () => {
    const halfPageHeight = document.documentElement.scrollHeight / 3;
    if (window.scrollY > halfPageHeight) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="body bg-dark text-white">
      <Container fluid>
        {/* Header Section */}
        <Header />
        {/* Navigation Section */}
        <Navbar bg="dark" expand="lg" variant="dark" className="my-4">
          <Navbar.Brand href="#home">World Hazara Council</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto justify-content-center">
              <Nav.Link href="#home">
                <Button variant="primary">Home</Button>
              </Nav.Link>
              <Nav.Link href="#about">
                <Button variant="primary">About</Button>
              </Nav.Link>
              <Nav.Link href="#team">
                <Button variant="primary">Team</Button>
              </Nav.Link>
              <Nav.Link href="#vision">
                <Button variant="primary">Vision</Button>
              </Nav.Link>
              <Nav.Link href="#mission">
                <Button variant="primary">Mission</Button>
              </Nav.Link>
              <Nav.Link href="/events">
                <Button variant="primary">Events</Button>
              </Nav.Link>
              <Nav.Link href="/news">
                <Button variant="primary">Statements</Button>
              </Nav.Link>
              <Nav.Link href="/membership">
                <Button variant="primary">Become a member</Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {/* Main Content Section */}
        <Row>
          <Col id="home">
            <Home />
          </Col>
        </Row>
        <Row>
          <Col id="counter">
            <Counter />
          </Col>
        </Row>
        <Row className="my-5">
          <Col id="about">
            <About />
          </Col>
        </Row>
        <Row className="my-5">
          <Col id="team">
            <AdministrationTeam />
          </Col>
        </Row>
        <Row className="my-5">
          <Col id="vision">
            <Vision />
          </Col>
        </Row>
        <Row className="my-5">
          <Col id="mission">
            <Mission />
          </Col>
        </Row>

        {/* Hashtag Feed */}
        <Row className="my-5">
          <Col>
            <StopHazaraGenocide />
          </Col>
        </Row>
        {/* Additional Info Section */}
        <Row className="my-5">
          <Col>
            <Info />
          </Col>
        </Row>
        {/* Question Box Section */}
        <Row className="my-5">
          <Col>
            <QuestionBox />
          </Col>
        </Row>
        {/* Scroll-to-Top Button */}
        {showScrollButton && (
          <Button
            variant="primary"
            onClick={scrollToTop}
            style={{
              position: "fixed",
              bottom: "50px",
              right: "50px",
              zIndex: 1000,
            }}
          >
            <i className="bi bi-arrow-up"></i>
          </Button>
        )}
        {/* Footer with Cookie Consent */}
        <CookieConsent />
      </Container>
    </div>
  );
}

export default App;
