import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { images } from "../config";

const teamMembers = [
  {
    name: "Akram Gizabi",
    position: "President",
    bio: "Akram Gizabi is a freelance writer and civil rights advocate, Former VOA jurnalist, Political activist in Afghanistan.",
    email: "info@worldhazaracouncil.info/",
    profilePicture: images.akramGizabi,
  },
  {
    name: "Reza Tavasoli",
    position: "Vice President",
    bio: "Reza is an experienced advocate for human rights and global awareness.",
    email: "info@worldhazaracouncil.info/",
    profilePicture: images.rezaTawasoli,
  },
  {
    name: "Qaderbakhsh Dost",
    position: "Vice President",
    bio: "Dost as a human rights advocate, and one of the main founder of the World Hazara Council.",
    email: "info@worldhazaracouncil.info/",
    profilePicture: images.QaderBakhshDost,
  },
];

const TeamMemberCard = ({ member }: { member: any }) => {
  console.log(member);
  return (
    <Col md={4} className="mb-4">
      <Card className="h-100">
        {member.profilePicture ? (
          <Card.Img
            variant="top"
            src={`${member.profilePicture}`}
            alt={`${member.name} profile`}
            style={{ height: "250px", objectFit: "cover" }}
          />
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "250px", backgroundColor: "#f8f9fa" }}
          >
            <i
              className="bi bi-person-circle"
              style={{ fontSize: "5rem", color: "#6c757d" }}
            ></i>
          </div>
        )}
        <Card.Body>
          <Card.Title>{member.name}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {member.position}
          </Card.Subtitle>
          <Card.Text style={{ textAlign: "justify", fontSize: "14px" }}>
            {member.bio}
          </Card.Text>
          <div className="d-flex flex-row">
            <Card.Text style={{ textAlign: "justify", fontSize: "18px" }}>
              Contact:{" "}
            </Card.Text>
            <Card.Link
              style={{
                textAlign: "center",
                fontSize: "14px",
                color: "#6c757d",
              }}
              href={`mailto:${member.email}`}
            >
              {member.name.split(" ")[0]}
            </Card.Link>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

const AdministrationTeam = () => {
  return (
    <Container className="mt-5 ">
      <h2 className="text-center mb-4">Current Board Members</h2>
      <p className="text-center">
        Meet our team of experts in the field of human rights and social
        justice.
      </p>
      <Row className="justify-content-md-center">
        {teamMembers.map((member, index) => (
          <TeamMemberCard key={index} member={member} />
        ))}
      </Row>
    </Container>
  );
};

export default AdministrationTeam;
