import React, { useState, useEffect } from "react";
import { getCookie, setCookie } from ".";
import { Button, Col, Container, Row } from "react-bootstrap";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the user has already consented
    const consent = getCookie("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    setCookie("cookieConsent", "true", 150); // Set consent for 150 days
    setIsVisible(false);
  };

  const handleDecline = () => {
    setCookie("cookieConsent", "false", 150); // Set non-consent for 150 days
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <Container fluid className="bg-dark text-white fixed-bottom p-3">
      <Row className="align-items-center">
        <Col md={8}>
          <p className="mb-0">
            This website uses cookies to enhance your browsing experience,
            analyze site traffic, and provide personalized content. Do you
            accept our use of cookies?
          </p>
        </Col>
        <Col md={4} className="text-md-right space-between">
          <Button variant="primary" className="mr-2" onClick={handleAccept}>
            Accept
          </Button>
          <Button variant="danger" onClick={handleDecline}>
            Decline
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CookieConsent;
