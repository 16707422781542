import React from "react";
import { Row, Col } from "react-bootstrap";

const StopHazaraGenocide = () => {
  return (
    <Row className="my-5 bg-light text-dark">
      <Col>
        <h2>
          <a
            href="https://www.facebook.com/hashtag/stopHazaraGenocide"
            target="_blank"
            rel="noreferrer"
            style={{ fontSize: "1.5rem", lineHeight: "1.7", color: "black" }}
          >
            #StopHazaraGenocide
          </a>
        </h2>
        <p style={{ fontSize: "1.1rem", lineHeight: "1.7" }}>
          The{" "}
          <a
            href="https://twitter.com/hashtag/StopHazaraGenocide"
            target="_blank"
            rel="noreferrer"
            style={{ fontSize: "1.2rem", lineHeight: "1.7", color: "#007bff" }}
          >
            #StopHazaraGenocide
          </a>{" "}
          movement highlights the ongoing persecution, violence, and systemic
          discrimination faced by the Hazara people, particularly in Afghanistan
          and Pakistan. For decades, Hazaras have endured targeted killings,
          bombings, forced displacement, and exclusion from political and social
          rights, often at the hands of extremist groups like the Taliban and
          ISIS.
        </p>
        <p style={{ fontSize: "1.1rem", lineHeight: "1.7" }}>
          The <strong>World Hazara Council</strong> is the leading organization
          behind these calls to stop the genocide, advocating for the protection
          and recognition of the Hazara community on a global scale.
        </p>
        <p style={{ fontSize: "1.1rem", lineHeight: "1.7" }}>
          The Hazara community demands that world leaders and key
          decision-makers take immediate action to:
        </p>
        <ul>
          <li>
            <strong>Recognize the Genocide:</strong> Acknowledge the systematic
            persecution of Hazaras as genocide under international law.
          </li>
          <li>
            <strong>Protect Hazara Communities:</strong> Implement urgent
            protective measures to safeguard Hazara populations, especially in
            conflict zones.
          </li>
          <li>
            <strong>Hold Perpetrators Accountable:</strong> Ensure that those
            responsible for the atrocities are prosecuted through international
            courts or sanctions.
          </li>
          <li>
            <strong>Ensure Political Representation:</strong> Advocate for the
            fair political inclusion and representation of Hazaras to secure
            their rights and safety.
          </li>
          <li>
            <strong>Support Humanitarian Aid:</strong> Increase humanitarian
            support for Hazara refugees and displaced individuals.
          </li>
        </ul>
        <p style={{ fontSize: "1.1rem", lineHeight: "1.7" }}>
          The global community is called upon to stand against ethnic violence
          and work to protect the Hazara people from further persecution.
          Together, with the leadership of the
          <strong>World Hazara Council</strong>, the goal is to ensure a future
          where Hazaras can live in peace and security, free from fear of
          violence and discrimination.
        </p>
      </Col>
    </Row>
  );
};

export default StopHazaraGenocide;
