import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export function Vision() {
  return (
    <Container fluid className="pt-5 pb-5 bg-light text-dark">
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <h1 className="display-4">Our Vision</h1>
          <p className="lead mt-4">
            Our vision at the World Hazara Council (WHC) is a world where every
            person has the right to live free from discrimination, violence, and
            oppression, regardless of their ethnicity, religion, or political
            beliefs.
          </p>
          <p style={{ fontSize: "1.1rem", lineHeight: "1.7" }}>
            We envision a society where marginalized populations, particularly
            the Hazara community, have equal opportunities for development,
            education, and social integration. We strive to create a world where
            human rights are respected and protected, and where humanitarian
            assistance is readily available to those in need.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
