import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Container, Spinner, Alert } from "react-bootstrap";
import { token } from "../../config";
import PageHeader from "../Header/PageHeader";

const EventDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [event, setEvent] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true);
      setError(null);
      if (!id) return;

      try {
        const response = await fetch(
          `https://www.eventbriteapi.com/v3/events/${id}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setEvent(data);
      } catch (error: any) {
        setError(`Error fetching event: ${error?.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [id]);

  if (loading)
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </Container>
    );

  if (error)
    return (
      <Container className="mt-4">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );

  if (!event)
    return (
      <Container className="mt-4">
        <Alert variant="info">Event not found.</Alert>
      </Container>
    );
  return (
    <div
      style={{
        textAlign: "center",
        width: "100%",
        backgroundColor: "#121212",
      }}
    >
      <PageHeader onBack={() => window.history.back()} />
      <Container className="mt-4 event-list-container">
        <Card>
          {event.logo && (
            <Card.Img
              variant="top"
              src={event.logo.url}
              alt={event.name.text}
            />
          )}
          <Card.Body>
            <Card.Title>{event.name.text}</Card.Title>
            <Card.Text>{event.description.text}</Card.Text>
            <Card.Text>
              <strong>Start:</strong>{" "}
              {new Date(event.start.local).toLocaleString()}
              <br />
              <strong>End:</strong> {new Date(event.end.local).toLocaleString()}
            </Card.Text>
            <Card.Text>
              <strong>Location:</strong>{" "}
              {event.venue && event.venue.address.localized_address_display}
            </Card.Text>
            <Card.Link
              href={event.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Details
            </Card.Link>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default EventDetails;
